/* Menu style */
.sidebar {
  background-color: var(--primary-main);
  color: #fff;
  width: 226px !important;
  padding: 8px 0;
  height: 100vh;
  /* Full height of viewport */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  /* Prevent shrinking */
  flex-grow: 0;
  /* Prevent growing */
  /* Use flexbox layout */
}

.scroll {
  overflow-y: auto;
  flex-grow: 1;
  /* Allow to grow and take remaining height */
}

.scroll::-webkit-scrollbar-track {
  background-color: #012244;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200, 0.5);
}

#resizable-div {
  background-color: var(--primary-main);
  transition: width 0.5s ease;
  overflow: hidden;
  /* To handle overflowing content when the div gets smaller */
}

.sidebarResp {
  background-color: var(--primary-main);
  width: 85px;
  color: #fff;
  padding: 8px 0;
  height: 100vh;
  /* Full height of viewport */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  /* Use flexbox layout */
}


#head-men2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-group {
  list-style: none;
  flex-grow: 1;
  /* Allow to grow and take remaining height */
  overflow-y: auto;
  /* Enable scrolling */
}

:is(.nav-group, .nav-group-resp) .MuiTabScrollButton-vertical {
  height: 30px;
}

.nav-group-resp {
  list-style: none;
  flex-grow: 1;
  /* Allow to grow and take remaining height */
  overflow-y: auto;
  /* Enable scrolling */
}

.nav-item {
  margin-bottom: 18px;
  font-size: 0.875rem;
}

.nav-item a {
  color: white !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  grid-gap: 10px;
  min-height: 24px;
}

svg {
  cursor: pointer;
}

.sub-nav-group {
  list-style: none;
  padding: 0;
  display: grid;
  margin-top: 10px;
}

.sub-nav-item {
  padding-left: 15px;
  min-height: 35px !important;
  align-items: center;
  display: flex;
}

.sub-nav-item:hover {
  background: var(--secondary-light);
  border-radius: 10px 0 0 10px;
}

.sub-nav-item-selected {
  background: var(--primary-light);
  border-radius: 10px 0 0 10px;
}

.sub-nav-item-wrapper {
  margin-left: 25px;
}

#head-men,
#head-men2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#head-men img,
#head-men2 img {
  height: 45px;
}

.toggle-div {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 15px;
  transition: transform 0.3s ease;
  z-index: 1000;
  cursor: pointer;
}

.toggle-btn {
  min-width: none;
  width: 35px;
  padding: 0;
}


.hidebar {
  width: 0 !important;
}

.sidebarRespHide {
  background-color: var(--primary-main);
  color: #fff;
  padding: 8px 0;
  /* Full height of viewport */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100dvh;
  z-index: 1000;
  position: absolute;
  width: 75px;
}

.sidebarHide {
  background-color: var(--primary-main);
  color: #fff;
  width: 226px !important;
  padding: 8px 0;
  /* Full height of viewport */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  /* Prevent shrinking */
  flex-grow: 0;

  height: 100dvh;
  z-index: 1000;
  position: absolute;
}

.nav-groupHide {
  margin-top: 20px;
}
.sidebarRespHide #head-men2 {
  margin-bottom: 20px
}