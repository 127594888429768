/* .login_page{
   font-family: "HelveticaW01-RoundedBd","Helvetica Neue","Helvetica","Arial","Microsoft YaHei New","Microsoft Yahei","SimSun","STXihei",sans-serif;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   margin: auto;
   background-color:#0270B1;

}  */

/* shadow for input */
input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/* login box  */
/* div.SingUpLoginBox {
    width:fit-content;
    margin:0 auto;
    text-align: center;
 } */
/* div#logoDiv {
   background: url(../img/logos/mawared_logo_white.svg) no-repeat center;
   width: 300px;
   height: 320px;
   background-size: 250px;
   margin: 10px auto 0 auto;
}
 div#logoDiv {
    background: url(../img/logos/mawared_logo_white.svg) no-repeat center;
    width: 300px;
    height: 320px;
    background-size: 250px;
    margin: 10px auto 0 auto;
 }
 div#loginDiv {
    background: url(../img/login_div.svg) no-repeat center;
    background-size:250px;
    width:400px;
    height:190px;
    display: inline-block;
 } 
 div#emailDiv {
    position: relative;
    top: 35px;
 }
 div#passDiv {
    position: relative;
    top: 55px;
    left: 120px;
    width: fit-content;
 }
 #divLogin{display:grid;grid-template-columns: 90% 10%;align-items: center;}
 div#buttonDiv {
    position: relative;
    top: 80px;
    left: 60px;
    width:290px;
    height:45px;
 }
 
 
 #subo{
    width: 100%;
    height: 40px;
 
 }
 
 input#email {  margin-left: 50px; }
 input#password { margin-left: 8px; }
 input#email,input#password {
    background-size: 30px 30px;
    border: solid 0px #991915;
    font-size: 20px;
    color: black;
    padding-left:5px;
    background-position: 194px 1px;
    width: 190px;
    height: 25px;
    font-weight: bold;
 }
 */


/* div#logosDiv2 div {
    margin: 0 15px;
 } */







#pwd-forgot {
   cursor: pointer;
   color: #0c0c0c;
   margin-left: auto !important;
}

#sms-forgot {
   color: red;
   font-size: 13px;
   text-align: center;
   margin-bottom: 15px;
}

#login-pwd {
   display: flex;
   align-items: center;
   grid-gap: 10px;
   margin-left: auto;
}

#pwd-for {
   position: absolute;
   top: 415px;
   right: 440px;
}

#form #buttonDiv {
   position: relative;
   top: 150px;
   left: 59px;
}

#form #forgotDiv {
   position: relative;
   top: 98px;
   left: 18px;
   height: 18px;
   width: 135px;
   cursor: pointer;
}

#form #forgotDiv:hover {
   border-bottom: 1px solid lightslategray;
}

#form #forgotDiv:focus {
   border-bottom: 1px solid lightslategray;
}

#form #subo {
   width: 180px;
   height: 33px;
   border-radius: 15px;
   background: transparent;
   border: none;
   cursor: pointer;
}

#form #emailDiv {
   position: relative;
   top: 63px;
}

#form #passDiv {
   position: relative;
   top: 93px;
   width: fit-content;
}

#form input#email,
#form input#password {
   margin-left: 25px;
}


#form input#email,
#form input#password {
   background-size: 30px 30px;
   border: none;
   outline: none;
   font-size: 14px;
   color: black !important;
   padding-left: 5px;
   background-position: 194px 1px;
   background: transparent;
   width: 250px;
   height: 25px;
   font-weight: 500;
}

#form2 #forgotDiv {
   position: relative;
   top: 55px;
   height: 20px;
   width: 113px;
   cursor: pointer;
}

#form2 #forgotDiv:hover {
   border-bottom: 1px solid lightslategray;
}

#form2 #forgotDiv:focus {
   border-bottom: 1px solid lightslategray;
}

#form2 #buttonDiv {
   position: relative;
   top: 90px;
   left: 37px;
   width: 145px;
}


#form2 #subo {
   width: 145px;
   height: 30px;
   border-radius: 15px;
   background: transparent;
   cursor: pointer;
   border: none;
}

#form2 #emailDiv {
   position: relative;
   top: 37px;
}

#form2 #passDiv {
   position: relative;
   top: 55px;
   width: fit-content;
}

#form2 input#email,
#form2 input#password {
   margin-left: 5px;
}


#form2 input#email,
#form2 input#password {
   background-size: 30px 30px;
   border: none;
   outline: none;
   font-size: 13px;
   /* color: #a7a7a7; */
   padding-left: 5px;
   background-position: 194px 1px;
   background: transparent;
   width: 200px;
   height: 25px;
   font-weight: 500;
}

#changePass .MuiDialogContent-root:first-child {
   padding-top: 10px;
}

@media only screen and (max-width: 300px) {
   .SingUpLoginBox {
      transform: scale(0.9) translate(-2%, 0);
   }
}

@media only screen and (max-width: 800px) and (min-height: 1000px) {
   .SingUpLoginBox {
      transform: scale(1.2) translate(0, 20%);
   }

}

@media only screen and (min-width: 1000px) and (min-height: 1000px) {
   .SingUpLoginBox {
      transform: scale(1.5) translate(0, 30%);
   }
}

@media only screen and (max-height: 600px) {
   .SingUpLoginBox {
      transform: scale(0.8) translate(0, -10%)
   }
}

@media only screen and (max-width: 1180px) and (min-width: 1070px) {
   #pwd-for {
      position: absolute;
      top: 415px;
      right: 290px
   }
}

@media only screen and (max-width: 1050px) and (min-width:850px) {
   #pwd-for {
      position: absolute;
      top: 415px;
      right: 190px;
   }
}

@media only screen and (max-width: 849px)and (min-width:500px) {
   #pwd-for {
      position: absolute;
      top: 415px;
      right: 150px;
   }
}

@media only screen and (max-width: 449px) {
   #pwd-for {
      position: absolute;
      top: 465px;
      right: 150px;
   }
}




/* new css */

/* @import "validator.css"; */
.login-page {
   position: fixed;
   background-repeat: no-repeat !important;
   margin: auto;
   width: 100%;
   height: 100%;
   padding: 22px;
}

.login-page2 {
   position: fixed;
   background-repeat: no-repeat !important;
   margin: auto;
   width: 100%;
   height: 100%;
}

.login-card {
   width: 100%;
   height: 100%;
   min-height: 500px;
   overflow-y: auto !important;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 22px;
}

.formLogin {
   width: 100%;
   display: grid;
   justify-items: center;
   grid-template-rows: auto 50px 40% 30px;
   align-items: end;
}

.webProfile {
   width: 100%;
   display: grid;
   align-items: end;
   justify-items: center;
   grid-template-rows: 0 auto;
}

.formLogin2 {
   width: 100%;
   display: grid;
   align-items: end;
   justify-items: center;
}

.formLogin #form {
   width: 300px;
   height: 300px;
   background: url(../img/login/formBlue.png) no-repeat center;
   background-size: 270px;
}

.formLogin2 #form2 {
   width: 220px;
   height: 220px;
   background: url(../img/login/formWhite.png) no-repeat center;
   background-size: 220px;
}

.webProfile #userPictureCont {
   position: relative;
   width: 140px;
   height: 140px;
   border-radius: 50%;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 145px;
   left: 0;
}

#userPictureCont #userPicture {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.formLogin #profile {
   text-align: center;
}

.formLogin #profile img {
   width: 150px;
}

.formLogin #userName {
   font-size: 25px;
   font-weight: 600;
   color: #308FEC;
   margin-top: 5px !important;
}

.formLogin #formMessage {
   max-width: 400px;
   width: 90%;
   margin: auto;
}

.formLogin #formMessage img {
   width: 100%;
}


.tabMobPart {
   width: 100%;
   height: 100%;
   background: url(../img/login/gradientMob.png) no-repeat center;
   display: grid;
   grid-template-rows: 25% auto 220px auto 20px;
   justify-content: center;
   justify-items: center;
   align-items: center;
}

.tabMobPart #logoIman {
   width: 60%;
   max-width: 250px;
   min-width: 150px;
   margin: auto;
}

.tabMobPart #welcomeMsg {
   width: 80%;
   max-width: 300px;
   min-width: 180px;
   margin: auto;
}

.tabMobPart #logoIman img,
.tabMobPart #welcomeMsg img {
   width: 100%
}


.mobileProfile {
   width: 100%;
   display: grid;
   align-items: end;
   justify-items: center;
   grid-template-rows: 0 auto 40px;
}

.mobileProfile #userPictureCont {
   position: relative;
   width: 110px;
   height: 110px;
   border-radius: 50%;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 115px;
   left: -1.5px;
}

.mobileProfile #profile {
   text-align: center;
}

.mobileProfile #profile img {
   width: 120px;
   display: grid;
}

.mobileProfile #userName {
   font-size: 22px;
   font-weight: 600;
   color: #fff;
   margin-top: 5px !important;
}

.mobileProfile #formMessage {
   max-width: 350px;
   width: 90%;
   margin: auto;
}

.mobileProfile #formMessage img {
   width: 100%;
}

.welcomePart {
   width: 100%;
   height: 100%;
   background: url(../img/login/gradientBig.png) no-repeat center;
   border-radius: 20px;
   display: grid;
   grid-template-rows: auto 150px;
   justify-content: center;
   justify-items: center;
   align-items: center;
}

.welcomePart #logoIman {
   width: 55%;
   margin: auto;
}

.welcomePart #welcomeMsg {
   width: 55%;
   margin: auto;
}

.welcomePart #logoIman img,
.welcomePart #welcomeMsg img {
   width: 100%
}


.flex-both {
   display: grid;
   justify-items: center;
   align-items: center;
   padding: 7% 16px;
   height: 100%;
}

.logos-grid {
   display: grid;
   gap: 10px;
   min-width: 250px;
   min-width: 250px !important;
   max-width: 500px !important;
   width: 100%;
}


/* div.MuiCard-root {
   margin-left: auto !important;
   min-width: 250px !important;
   max-width: 500px !important;
   width: 100%;
} */


div.MuiCardContent-root:last-child {
   padding-bottom: 12px;
}

div.MuiCardContent-root {
   /* border-radius: 32px !important; */
   /* padding-top: 50px; */
   background-color: #ffffff;
   min-width: 200px !important;

}

/* div.MuiOutlinedInput-root{
   padding: 5px !important;
} */
input#IEmail,
input#Id {
   height: 30px !important;
}

:is(.login-page, .login-page2) input:-webkit-autofill,
:is(.login-page, .login-page2) input:-webkit-autofill:hover,
:is(.login-page, .login-page2) input:-webkit-autofill:focus,
:is(.login-page, .login-page2) input:-webkit-autofill:active {
   -webkit-box-shadow: 0 0 0px 1000px transparent inset;
   box-shadow: 0 0 0px 1000px transparent inset;
   -webkit-text-fill-color: black;
   /* Set the desired text color here */
   transition: background-color 5000s ease-in-out 0s, -webkit-text-fill-color 5000s ease-in-out 0s;
}

div#logosDiv {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   height: 50px;
   width: 100%;
   margin-top: 40px;
   align-items: center;
}

#form1 {
   display: grid;
   width: 100% !important;
   padding: 0 5% !important;
   gap: 25px;
   margin-top: 10% !important;
}

div#iManLogo {
   background: url(../img/logos/logo_iman_full.png) no-repeat center;
   height: 120px;
   background-size: 250px;
}

div#welcome {
   font-size: 24px;
   font-weight: 600;
   padding-top: 10px;
   padding-bottom: 20px;
}

div#auth {
   font-family: "poppins";
   font-weight: "regular";
   font-size: 15px;
}

div#logoBig {
   background: url(../img/logos/mawared_logo_white_left.svg) no-repeat center;
   width: 300px;
   height: 320px;
   background-size: 250px;
   margin: 10px auto 0 auto;

}

div#logosDiv2 {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   height: 50px;
   width: 100%;
   margin: 0;
   margin-top: 10px;
}

div#bqDiv {
   background: url(../img/logos/mini_logo_bq_white.png) no-repeat;
   width: 40px;
   height: 40px;
   background-size: 40px;
   margin: 0 10px;
}

div#liberoDiv {
   background: url(../img/logos/logo_libero_v_white.png) no-repeat;
   width: 40px;
   height: 40px;
   background-size: 50px;
   margin: 0 10px;

}

div#webTelcoDiv {
   background: url(../img/logos/logo_WebTelco_white.png) no-repeat;
   width: 40px;
   height: 40px;
   background-size: 40px;
   margin: 0 10px;
}

div#irahtiDiv {
   background: url(../img/logos/logo_irahati_white.png) no-repeat;
   width: 95px;
   background-size: 100px;
   margin: 0 10px !important;
}

div#smartTelcoDiv {
   background: url(../img/logos/smart_telco_white.png) no-repeat;
   width: 80px;
   height: 40px;
   background-size: contain;
   margin: 0 0 0 10px;
}

div#smsOrDiv {
   background: url(../img/logos/SmsOr.png) no-repeat;
   width: 60px;
   height: 40px;
   background-size: contain;
   margin: 0 10px;
}

div#ri9abaDiv {
   background: url(../img/logos/logo-ri9aba.png) no-repeat;
   width: 60px;
   height: 35px;
   background-size: contain;
   margin: 10px;
}

#partnerDiv {
   background: url(../img/logos/logo_itihadi_white.svg) no-repeat;
   width: 80px;
   height: 30px;
   background-size: contain;
}

#partnerArDiv {
   background: url(../img/logos/logo_itihadi_ar_white.svg) no-repeat;
   width: 90px;
   height: 30px;
   background-size: contain;
}

#startechDiv {
   background: url(../img/logos/logo_startech.svg) no-repeat;
   width: 100px;
   height: 50px;
}

#digitartsDiv {
   background: url(../img/logos/logo_digitarts_boutiqa.png) no-repeat;
   width: 120px;
   height: 35px;
   background-size: contain;
}

div#authBottom {
   width: 100%;
   height: fit-content;
   text-align: center;
   color: var(--primary-main);
   font-weight: bold;
   font-size: 14px;
}

.tabMobPart #authBottom {
   color: white !important;
}

.CardContent {
   text-align: center;
}


@media (max-width : 900px) {

   #partnerDiv,
   #partnerDiv {
      background: url(../img/logos/logo_itihadi_white.svg) no-repeat;
      width: 60px;
      height: 20px;
      background-size: contain;
   }

   #partnerArDiv {
      background: url(../img/logos/logo_itihadi_ar_white.svg) no-repeat;
      width: 60px;
      height: 20px;
      background-size: contain;
   }

   #startechDiv {
      background: url(../img/logos/logo_startech.svg) no-repeat;
      width: 60px;
      height: 30px;
   }

   #digitartsDiv {
      background: url(../img/logos/logo_digitarts_boutiqa.png) no-repeat;
      width: 80px;
      height: 20px;
      background-size: contain;
   }

   div#iManLogo {
      background: url(../img/logos/logo_iman_full.png) no-repeat center;
      height: 80px;
      background-size: 180px;
   }
}