* {
  box-sizing: border-box;
}

:root {
  /*  primary*/
  --primary-light: #034277;
  --primary-main: #012244;
  --primary-dark: #001b33;

  /* secondary*/
  --secondary-light: #ff3333;
  --secondary-main: #ed0000;
  --secondary-dark: #b30000;

  /*grey*/
  --grey-light: #ccc;
  --grey-main: #b4b4b4;
  --grey-dark: #434343;
  --body-grey: #f9fafb;
  --hover-grey: #eee;
  --BGconditionalRowStyles: #dde8ef;
  --TypographyGrey: #757575;
  --IconDefaultPic: #dddddd;
  /*gap*/
  --formgap: 14px;
  /*BorderRaduis*/
  ---globalborder-radius: 5px;
  ---global2border-radius: 50%;

  --bg-color: white;
  --Text-color: #353535;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: #cbced0;
  border-radius: 20px;
  border: 5px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b9bbbb;
}

/* .sc-fqkvVR :hover{
background-color:var(--hover-grey) !important;
} */

body {
  margin: 0;
  padding: 0;
  background-color: var(--body-grey);
  overflow: hidden;
}

#head-men {
  width: 100%;
  text-align: center;
  /* padding: 16px; */
  padding: 8px;
}

#head-men img {
  height: 30px;
}

.content {
  display: flex;
  flex: 1;
}

.smallAuto {
  margin-bottom: 30px;
  margin-top: 20px;
  justify-items: center;
  width: 250px;
}

#contenu {
  display: block;
  height: 100vh;
  width: 100%;
  transition: width 0.3s ease;
  overflow-y: hidden;
}

#header {
  color: #353535;
  padding: 2px 2px 0px 30px;
  /*position: fixed;*/
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
  align-items: center;
  width: 100%;
  background-color: white;
}

#header #disconnect {
  margin-left: auto;
}

#disconnect {
  display: flex;
  align-items: center;
  gap: 16px;
}

#footer {
  background-color: #ebf0f2;
  color: black;
  padding: 4px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  font-size: 12px;
  display: grid;
  grid-template-columns: 28% 40% 28%;
  justify-content: center;
  box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.1);
}

#main-content {
  flex: 1;
  padding: 10px 20px 80px 20px;
  overflow-y: auto !important;

  flex: 1;
  overflow-y: auto !important;
  height: 100dvh;
}

.table-header {
  display: flex;
  width: 100%;
  flex-flow: row-reverse wrap;
  margin: auto;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  gap: var(--formgap);
  margin-top: 10px;
}

.table-header1 {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  gap: var(--formgap);
  margin-top: 10px;
}

.button-flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}

.button-flex-wrapper-gap {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  gap: 5px;
}

.flex-wrapper {
  display: flex;
  gap: var(--formgap);
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.flex-wrapper2 {
  display: flex;
  gap: var(--formgap);
  text-align: center;
  align-items: center;
  margin-top: 4px;
  /* margin-bottom: 40px;  */
}

.trs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  text-align: center;
  align-items: center;
  margin-top: 4px;
}

.role-usr {
  width: 400px;
}

.addrole-usr {
  display: flex;
  flex-direction: column;
}

.table-header .search {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
}

.table-header2 {
  display: flex;
  width: 100%;
  flex-flow: row-reverse wrap;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  gap: var(--formgap);
  margin-top: 14px;
}

/* .table-header2 .search {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
} */

.search-icon {
  color: grey;
  padding-left: 5px;
  background-color: white;
}

.rowGrid {
  display: grid;
  margin: 0;
  align-items: center;
  grid-gap: 5px;
}

.form {
  width: 100% !important;
  display: grid;
  grid-gap: var(--formgap);
}

.date-picker .MuiInputAdornment-root {
  margin-right: 10px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hjBYuK:nth-of-type(n) {
  background-color: var(--hover-grey) !important;
  background-color: var(--hover-grey) !important;
}

.gkvtLZ:nth-of-type(n) {
  background-color: var(--hover-grey) !important;
}

.sc-eeDRCY {
  border-radius: var(---globalborder-radius);
  background-color: var(--hover-grey) !important;
}

/* These classes are used for placing icons in datatable */
.oneIcons {
  grid-template-columns: 25px;
}

.twoIcons {
  grid-template-columns: repeat(2, 25px);
}

.threeIcons {
  grid-template-columns: repeat(3, 25px);
}

.fourIcons {
  grid-template-columns: repeat(4, 25px);
}

.fiveIcons {
  grid-template-columns: repeat(5, 25px);
}

.addpicture {
  display: flex;
  flex-direction: column;
  gap: 8px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
  border-radius: var(---global2border-radius);
  position: absolute;
  color: rgb(145, 158, 171);
  background-color: rgba(145, 158, 171, 0.08);
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#grid1 {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
}

.grid2 {
  display: grid;
  height: fit-content;
}

.grid0 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 250px;
  /* gap: 100px; */
  text-align: center;
}

.gridnrc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 250px;
  padding-left: 65px;
  /* gap: 100px; */
}

.grid3 {
  padding-left: 65px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  display: grid !important;
  font-size: 12px !important;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
}

.photoCard.MuiCard-root {
  position: relative;
  min-width: 0px !important;
  padding: 10px;
}

.typography-photo-permis {
  text-align: center;
  font-size: 12px !important;
  color: var(--TypographyGrey);
  padding-bottom: 4px;
}

.photodeprofileusr {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  object-fit: cover !important;
}

.photodeprofileusr1 {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
}

.profile-img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.mediacard {
  width: 130px;
  height: 130px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  margin: auto !important;
  border-radius: var(---global2border-radius);
  overflow: hidden !important;
  position: relative !important;
}

.mediacard0 {
  width: 130px;
  height: 130px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  margin: auto !important;
  overflow: hidden !important;
  position: relative !important;
}

.mediacard2 {
  width: 250px;
  height: 250px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  margin: auto !important;
  border-radius: var(---global2border-radius);
  overflow: hidden !important;
  position: relative !important;
}

.mediacard3 {
  width: 250px;
  height: 250px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  margin: auto !important;
  overflow: hidden !important;
  position: relative !important;
}

.Table {
  border-radius: var(---globalborder-radius);
  box-shadow: 0px 2px 15px -1px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.08);
  /* overflow-y: auto;
max-height:calc(100vh - 185px) ; */
}

.Button-close {
  background-color: gray !important;
}

.Alert {
  position: fixed !important;
  bottom: 30px !important;
  margin: 0 20px !important;
  right: 0;
  width: fit-content;
  color: white;
}


.avatar {
  /* width: 40px !important;
  height: 40px !important; */
  cursor: pointer !important;
  object-fit: cover !important;
}

.userprofile-pic {
  /* width: 40px;
  height: 40px; */
  border-radius: 50%;
  overflow: hidden;
  margin: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.userprofile-empty {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 5px;
}

.profileImg-empty {
  justify-content: center;
  display: flex;
  align-items: center;
}

.img-holder {
  width: 35px !important;
  height: 35px !important;
  cursor: pointer !important;
  object-fit: cover !important;
}

.editable-image-container {
  height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editable-photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-circle-icon {
  color: var(--IconDefaultPic);
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center;
}

.formgap {
  gap: var(--formgap);
}

.name-Center {
  margin-right: auto;
  margin-left: 10px;
}

.Button-submit {
  background-color: var(--primary-main) !important;
}

.MuiButton-root.Mui-disabled {
  color: #b4b4b4 !important;
  background-color: var(--primary-light) !important;
}

.btn-pageempty {
  color: var(--primary-main);
}

.error-content {
  display: flex;
  justify-items: center;
}

.toltip-note {
  border: 1px solid black;
  background-color: blue !important;
  font-size: 12px !important;
}

.editibale {
  padding: 3px 6px !important;
  height: 24px;
  /* width: 100%; */
  min-width: 50px !important;
  max-width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center !important;
}

.error-template {
  display: grid;
  justify-items: center;
  align-items: center;
}

/* .MuiTabs-indicator{
  bottom: 20px !important;
} */

.datepicker-error {
  border: 1px solid red !important;
  border-radius: 5px;
}

.filtr-search {
  display: flex;
  gap: 8px;
}

.editibaleWilCom {
  font-size: 12px !important;
  color: black !important;
  display: grid;
  width: 100% !important;
  grid-template-columns: 1fr 2fr;
}

.kJnSxO:nth-of-type(n) {
  background-color: var(--hover-grey) !important;
}

.smaller-cell {
  width: 1% !important;
  padding: 0px !important;
}

.error-datepicker {
  color: red;
  font-size: 12px;
  margin-left: 2px;
}

.affectation-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  /* height: calc(100vh - 255px); */
}

#affectation-table1 {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  /* height: calc(100vh - 255px); */
}

/* #affectation-table1 {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
} */
.filter {
  width: 230px;
}

.emp-nom-prenom {
  text-transform: uppercase !important;
  padding: 0px 1px 16px 1px !important;
  font-size: larger !important;
  font-weight: 500 !important;
}

.emp-nom-prenom1 {
  text-transform: uppercase;
  padding: 8px 1px 16px 1px;
  font-size: larger;
  font-weight: 500;
}

.User-nickname {
  font-weight: 500;
  margin: 4px 0;
  font-size: 15px;
  text-align: center;
  color: white;
  margin-top: -2px;
}

.User-name {
  font-weight: 400;
  margin: 4px 0;
  font-size: 12px;
  text-align: center;
  /* margin-top: -10px; */
  /* color: grey; */
  color: white;
}

.User-nickname:last-of-type {
  margin-bottom: -5px;
}

.expandedTitle1 {
  /* text-align: center !important; */
  text-transform: uppercase !important;
}

.expanded-container {
  /* padding-left: 65px !important; */
  padding-top: 12px !important;
  padding-bottom: 8px !important;
  width: 100% !important;
  display: grid !important;
  font-size: 12px !important;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)) !important;
  text-align: center;
}

.expanded-container1 {
  /* padding-left: 65px !important; */
  padding-right: 650px;
  padding-top: 12px !important;
  padding-bottom: 8px !important;
  width: 100% !important;
  display: grid !important;
  font-size: 12px !important;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)) !important;
  text-align: center;
}

.expanded-div {
  justify-content: center;
  display: flex;
  font-weight: 500;
}

.Tabs-file {
  display: flex;
  width: 100%;
  justify-content: center;
}

.No-FileList-Table {
  text-align: center;
  margin-top: 20px;
}

.FileList-Table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 100%;
}

/* .addrole-usr.MuiDialogContent-root {
  overflow: hidden;
} */

.container-error {
  display: grid;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px;
}

.container-empty {
  display: grid;
  justify-content: center;
  align-content: center;
  /* width: 100%; */
  /* height: 100%; */
  /* margin-bottom: 28px; */
}

.empty-content {
  text-align: center;
}

.Btn-acceuil-empty {
  width: fit-content !important;
  padding: 10px !important;
  text-decoration: none !important;
  color: white !important;
  border-radius: 250px !important;
  background-color: var(--secondary-light) !important;
  justify-self: center !important;
}

.Btn-acceuil-err {
  display: flex !important;
  width: 250px !important;
  padding: 3px !important;
  text-decoration: none !important;
  color: white !important;
  background-color: var(--primary-main) !important;
}

.Title-empty {
  font-size: 26px;
  text-align: center;
  color: var(--primary-main);
  font-weight: 900;
  text-transform: uppercase;
}

.Title-err {
  font-size: 24px;
  text-align: center;
}

.Text-empty {
  text-align: center;
  color: #757575;
}

#err {
  width: 80%;
  padding: 16px;
}

.div-cmp-flex {
  width: 100% !important;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}

.file-icon {
  transform: rotate(45deg) !important;
}

#userForm {
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important; */
  grid-gap: 10px;
  align-items: center;
}

.test1 {
  display: flex;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
}

.div-monlof-prd5 {
  display: grid;
  gap: 3px;
  grid-template-columns:
    minmax(19%, auto) minmax(19%, auto) minmax(19%, auto) minmax(19%, auto) minmax(19%, auto) !important;
  grid-template-columns:
    minmax(19%, auto) minmax(19%, auto) minmax(19%, auto) minmax(19%, auto) minmax(19%, auto) !important;
  width: 100% !important;
}

.cli-page {
  height: 90%;
  grid-template-rows: minmax(0, 1fr) 0fr minmax(0, 2fr);
  display: grid;
  gap: var(--formgap);
  overflow: hidden;
}

.div-monlof-add3 {
  display: grid;
  gap: 3px;
  grid-template-columns: minmax(33%, auto) minmax(33%, auto) minmax(33%, auto) !important;
  width: 100% !important;
}

.expandedTitle {
  /* text-align: center !important; */
  text-transform: uppercase !important;
}

.ent-cmp-expanded {
  padding-left: 65px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  width: 100% !important;
  display: grid !important;
  font-size: 12px !important;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
}

.det-box {
  width: 100% !important;
  /* margin-bottom: 50px !important; */
  background-color: var(--body-grey) !important;
  display: grid !important;
  /* grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) 7fr !important; */
  /* flex : 2 !important;  */
  height: 275px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.det-page {
  height: 90% !important;
  grid-template-rows: minmax(0, 1fr) 0fr minmax(0, 2fr);
  display: grid;
  gap: var(--formgap);
  overflow: hidden;
}

.flex-tow-item {
  display: flex !important;
  gap: 10px;
  justify-content: space-between !important;
  font-size: 12px !important;
  align-items: center;
}

.flex-tow-item-column {
  display: flex !important;
  align-items: center !important;
  flex-direction: column;
}

.emp-grid {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  width: 100% !important;
  display: grid !important;
  font-size: 12px !important;
  background-color: var(--body-grey);
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  overflow-y: auto;
  align-content: start;
}

.form-cli-prt1 {
  display: grid;
  width: 100%;
  gap: var(--formgap);
}

.det-box1 {
  width: 100%;
  /* margin-bottom: 50px; */
  background-color: var(--body-grey);
  display: grid;
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) 7fr;
  /* flex : 2;  */
  /* height: 100px; */
  padding-left: 10px;
  padding-right: 10px;
}

.det-page1 {
  height: 90%;
  grid-template-rows: minmax(0, 1fr) 0fr minmax(0, 2fr);
  display: grid;
  gap: var(--formgap);
  overflow: hidden;
}

.tooltip-Dtable-joindre {
  padding: 5px 20px;
  font-size: 12px;
  text-align: left;
  flex-direction: column;
  gap: 5px;
  width: 240px;
}

.tooltip-Dtable {
  /* color: white; */
  padding: 5px 20px;
  font-size: 12px;
  /* background-color: var(--primary-light); */
  /* border-radius: 10px; */
  /* max-width: 500px; */
  /* display: flex; */
  text-align: left;
  flex-direction: column;
  gap: 5px;
  width: 200px;
  /* box-shadow: 0px 2px 15px -1px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.08); */
}

.tooltip-Dtable-flex {
  text-align: left;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.tooltip-Dtable h5 {
  font-size: 12px;
  color: gray;
  margin: 0;
  text-transform: uppercase;
  font-weight: normal;
}

.form-cli-prt1 {
  display: grid;
  width: 100%;
  gap: var(--formgap);
}

.form-cli-prt1 {
  display: grid;
  width: 100%;
  gap: var(--formgap);
}

.commune {
  width: 200px !important;
}

.button-upload-input {
  border-radius: 20px;
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  padding: 25%;
}

/* .cardMediaEditFile {
} */
.editable-file {
  display: grid !important;
  row-gap: 20px;
  justify-items: center;
  align-items: center;
}

.file-edit-pos {
  align-items: center;
  justify-content: space-between;
  height: 100% !important;
  /* height: calc(100vh - 330px); */
  flex-direction: column;
  overflow: auto !important;
}

.fix-width {
  max-width: none !important;
  max-width: none !important;
}

.flex-auto {
  flex: 2;
}

.image-pos {
  height: 85%;
  /* width: 70%; */
  /* overflow: auto; */
}

.editbale-file-title {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
}

.Button-uploadEditFile {
  border: 0.5px solid var(--grey-light) !important;
  color: var(--primary-main) !important;
  background-color: white !important;
  margin-top: 10px !important;
}

/*add by imene*/
.MuiAutocomplete-paper {
  /* display: inline-block; */
  min-width: max-content !important;
  /*changer le width de l'autocomplete*/
}

.remove-padding {
  padding: 0px !important;
}

.remove-padding1 {
  padding: 2px !important;
  margin-bottom: 5px !important;
}

.remove-margin {
  margin: 0px !important;
}

.commune {
  width: 200px !important;
}

.MuiPaper-root-MuiAutocomplete-paper {
  background-color: "red !important";
  color: "white";
}

.MuiCheckbox-root.Mui-checked {
  color: #012244 !important;
}

.centered-container {
  display: grid !important;
  gap: 10px;
}

.editibale:hover {
  background-color: var(--bg-color) !important;
  cursor: text;
}

.cell {
  background-color: #cfe8fc;
}

.cell1 {
  background-color: red;
}

/* CSS PRINT COMPONENT */
.print-flex {
  width: 100%;
  display: flex;
  text-align: center !important;
  align-items: center !important;
  align-content: center;
  gap: 10px;
  justify-content: space-between !important;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 80px;
}

.print-flex2 {
  width: 100%;
  display: flex;
  text-align: center !important;
  align-items: center !important;
  align-content: center;
  gap: 10px;
  justify-content: space-between !important;
  justify-content: center;
  margin-top: 20px;
  /* margin-bottom: auto; */
  /* height: 100px; */
  height: 120px;
}

.print-date {
  margin-top: 10mm;
  margin-bottom: 40mm;
  text-align: right;
  font-size: 12pt;
  font-weight: 600;
  text-decoration: underline;
}

.print-date2 {
  margin-top: auto;
  justify-self: start;
  align-self: end;
  text-align: left;
  font-size: 12pt !important;
  font-family: "Verdana", sans-serif !important;
  text-decoration: underline;
}

.print-date3 {
  margin-top: auto;
  justify-self: start;
  align-self: end;
  text-align: left;
  font-size: 12pt !important;
  font-family: "Verdana", sans-serif !important;
  text-decoration: underline;
  margin-top: 4px;
}

.print-style {
  font-size: 9pt;
  font-family: "Verdana", sans-serif !important;
  font-weight: 400;
}

.print-logo {
  margin-right: auto;
  justify-self: start;
  width: auto !important;
  /* padding-bottom: 50px !important; */
  height: 100%;
  align-self: center;
  mix-blend-mode: multiply;
}

.print-title {
  text-align: center !important;
  align-self: center;
  font-size: 12pt;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold;
}

.print-title-small {
  margin-top: 3px;
  text-align: left !important;
  align-self: left;
  font-size: 12pt;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold;
  margin-bottom: 8px;
}

.print-title2-small {
  text-align: left !important;
  align-self: center;
  font-size: 10pt;
  font-family: "Verdana", sans-serif !important;
  font-weight: 600;
  margin-top: 8px;
}

.print-title3-small {
  margin-top: auto;
  justify-self: start;
  align-self: end;
  text-align: left;
  font-size: 12pt !important;
  font-family: "Verdana", sans-serif !important;
  margin-top: 8px;
}

.print-row {
  display: flex;
  text-align: left;
  width: 70%;
}

.print-flex-column {
  color: #eb2429 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.print-flex-column1 {
  color: #eb2429 !important;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.print-flex-column2 {
  color: #eb2429 !important;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.print-body {
  align-items: center;
  width: 210mm;
  height: 297mm;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

/* .print-footer {
  background-color: white !important;
} */

/* CSS BON DE COMMANDE */
.table-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  margin-top: 15px;
}

.table {
  border: 2px solid black;
}

.print-table {
  border: 2px solid black;
  table-layout: fixed;
}

.print-pdf {
  table-layout: fixed;
}

.tableCell-pdf {
  border: none !important;
  /* border: 2px solid black; */
  text-align: center !important;
  padding: 0px !important;
  border-left: 1px solid black !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  height: 22px !important;
}

.tableCell {
  border: none !important;
  /* border: 2px solid black; */
  text-align: center !important;
  padding: 0px !important;
  border-left: 2px solid black !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  height: 22px !important;
}

.tableCellLeft {
  border: none !important;
  text-align: left !important;
  padding: 0px !important;
  border-left: 2px solid black !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  padding-left: 4px !important;
  height: 20px !important;
}

.border-top-pdf {
  border-top: 1px solid black !important;
}

.border-right-pdf {
  border-right: 1px solid black !important;
}

.tableCellRight-pdf {
  /* border: 1px solid black; */
  text-align: right !important;
  border-bottom: 1px solid black !important;
  /* border-top: 1px solid black !important; */
  border-left: 1px solid black !important;
  padding: 2px !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold !important;
}

.tableCellRight {
  border: 2px solid black;
  text-align: right !important;
  border-bottom: 2px solid black !important;
  padding: 2px !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold !important;
}

.tableHeadCell-pdf {
  /* border-top: 1px solid black !important; */
  border-bottom: 1px solid black !important;
  border-left: 1px solid black !important;
  text-align: center !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold !important;
  padding: 1px 2px !important;
  line-height: 1.25 !important;
  height: 25px !important;
}

.tableHeadCell {
  border: 2px solid black;
  border-bottom: 2px solid black !important;
  text-align: center !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold !important;
  padding: 1px 2px !important;
  line-height: 1.25 !important;
  height: 25px !important;
}

.tableCell2 {
  padding: 2px !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold !important;
}

.table-cell-empty-pdf {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  border-top: none !important;
  border-bottom: none !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold !important;
  padding: 4px !important;
}

.table-cell-empty {
  border-left: 2px solid white !important;
  border-right: 2px solid white !important;
  border-top: none !important;
  border-bottom: none !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: bold !important;
  padding: 4px !important;
}

.cellOrange {
  background-color: rgb(238, 186, 45);
}

.cellGrean {
  background-color: rgb(199, 237, 203);
}

.cellBleu {
  background-color: rgb(238, 233, 246);
}

.cellGrey {
  background-color: lightgray;
}

.info-container {
  width: 100%;
  border: 3px double #000;
  border-radius: 10px;
  padding: 4px;
  font-size: 10pt;
  font-family: "Verdana", sans-serif !important;
  justify-self: self-end !important;

  /* margin-top: 50px; */
  text-align: left !important;
}

.info-container p {
  margin: 1px !important;
}

.info-container-facture {
  margin-bottom: -35px !important;
  width: 100%;
  border: 3px double #000;
  border-radius: 10px;
  padding: 4px;
  font-size: 10pt;
  font-family: "Verdana", sans-serif !important;
  justify-self: self-end !important;
  text-align: left !important;
}

.info-container-facture p {
  margin: 5px !important;
}

.print {
  width: 100%;
  display: grid;
}

.table-cell-align-right {
  text-align: right !important;
}

.time-picker .MuiInputAdornment-root {
  margin-right: 10px !important;
}

/***************** decharge file *************/
.print-decharge-footer {
  display: flex;
  margin-top: 30mm;
  justify-content: space-around;
}

.print-decharge-container {
  text-align: left;
  /* width: 210mm;
  height: 297mm; */
  padding: 0;
  font-family: "Verdana", sans-serif !important;
  font-size: 14pt;
  line-height: 1.5;
}

.print-decharge-title {
  font-family: "Verdana", sans-serif !important;
  text-align: center;
  font-size: 16pt;
  margin: 0;
  margin-bottom: 10mm;
}

.print-title-big {
  text-align: center;
  font-size: 14pt;
  font-family: "Verdana", sans-serif !important;
  font-weight: 700;
  margin: 0;
  margin-top: 10mm;
}

.print-date-big {
  margin-top: 5mm;
  margin-bottom: 5mm;
  text-align: right;
  font-size: 12pt !important;
  font-family: "Verdana", sans-serif !important;
  font-weight: 600;
  text-decoration: underline;
}

.print-decharge-date {
  margin-top: 10mm;
  margin-bottom: 40mm;
  text-align: right;
  font-size: 12pt;
  font-family: "Verdana", sans-serif !important;
  font-weight: 600;
  text-decoration: underline;
}

.print-decharge-header {
  border-bottom: 1px solid #eb2429;
  font-family: "Verdana", sans-serif !important;
  color: #eb2429 !important;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  /* text-align: center !important; */
  /* align-items: center !important; */
  gap: 20px;
  /* justify-content: space-between !important; */
  margin-top: 8px;
  height: 80px;
  font-size: 11pt !important;
}

.print-decharge-logo {
  justify-self: left;
  margin-right: auto;
  width: 20% !important;
  padding: 5px;
  height: auto;
}

/**********************/
.center-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

/* reqit cmd */
#form-cmd-reqit {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  gap: 12px;
  border-radius: 5px;
  padding: 5px;
}

.form-reqit-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-reqit-row label {
  font-size: 13px;
  color: #333;
  font-weight: 600;
  flex: 1;
}

.form-reqit-value {
  font-size: 13px;
  color: #333;
  flex: 1;
  text-align: right;
  padding-right: 5px !important;
}

.form-reqit-textfield input[type="text"] {
  text-align: right;
  padding-right: 5px !important;
  /* color: #5fa8d3; */
  font-size: 13px !important;
}

#form-cmd-allrel {
  margin: auto;
  width: 100%;
  justify-content: center;
  gap: 12px;
}

/* ***************** */
/* **************** */
.table-select {
  display: flex;
  justify-content: space-between;
  /* gap: 15px; */
  align-items: center;
  /* margin-bottom: 16px; */
  /* Ajustez cet espace selon vos besoins */
  /* padding: 10px; */
  /* Ajoutez du padding si nécessaire */
  /* background-color: #f5f5f5; */
  /* Ajoutez un fond si nécessaire */
  /* border-bottom: 1px solid #ddd;
  border-radius: 5px; */
  gap: 10px;
}

.table-select1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f5f5f5; */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px;
  height: 32px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  align-items: center;
  gap: 6px;
}

.div-white-space {
  padding: 3px 6px !important;
  height: 24px;
  /* width: 100%; */
  min-width: 50px !important;
  max-width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center !important;
}

/* css commande   */
.vch-cmd-add {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  gap: 12px;
  /* background: linear-gradient(#f0f0f0, #ffffff, #f0f0f0); */
  border-radius: 5px;
  padding: 5px;
}

.button-add-vch-cmd {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  gap: 10px;
}

.flex-vch-cmd {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.cmd-rel-title {
  font-size: 13px !important;
  text-align: center !important;
}

.cmd-rel-body {
  color: #353535 !important;
  font-size: 12px !important;
  text-align: center !important;
}

.button-file {
  background-color: var(--secondary-main) !important;
}

.button-file :disabled {
  background-color: var(--secondary-main) !important;
}

/* ************************ */
/* .print-footer {
  margin-top: 200px;
} */

.full-width {
  width: 100%;
}

.full-width-half {
  width: 50%;
}

.file-background-image {
  width: 21cm;
  height: 29.7cm;
  position: relative;
  color: red;
}

.number-align {
  text-align: end !important;
  /* font-family: "Roboto", sans-serif !important; */
}

.table-header-cell {
  text-align: center !important;
  color: white !important;
  font-size: 13px !important;
  text-transform: uppercase;
}

.tableCell3-pdf {
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: 2px solid black;
  text-align: right !important;
  padding: 0px !important;
  padding-right: 2px !important;
  border-left: 1px solid black !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  height: 22px !important;
}

.tableCell3 {
  border: none !important;
  text-align: right !important;
  padding: 0px !important;
  padding-right: 2px !important;
  border-left: 2px solid black !important;
  font-size: 10pt !important;
  font-family: "Verdana", sans-serif !important;
  height: 22px !important;
}

.number-font-family {
  /* font-family: "Roboto", sans-serif !important; */
  font-weight: 500 !important;
}

.demande-list-trs {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 175px) !important;
  cursor: pointer;
  grid-row: 1/2;
  background-color: #ececec;
  border-radius: 8px 8px 0 0;
  margin-top: 2px;
}


.demande-list-prv {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 230px) !important;
  cursor: pointer;
  grid-row: 1/2;
  background-color: #ececec;
  border-radius: 8px 8px 0 0;
  margin-top: 2px;
}


.table-container-trs {
  /* height: calc(100vh - 210px); */
  height: calc(100vh - 300px);
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.table-container-trs2 {
  /* height: calc(100vh - 210px); */
  height: calc(100vh - 280px);
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.additional-info-trs {
  display: grid;
  gap: 10px;
  margin-top: 10px;
}

.demande-details-trs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-row: 1/3;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #ececec;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 2px;
}

.demande-list-vch {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 230px) !important;
  cursor: pointer;
  grid-row: 1/2;
  background-color: #ececec;
  border-radius: 8px 8px 0 0;
  margin-top: 2px;
}

.demande-container-trs {
  display: grid;
  grid-template-columns: 270px auto;
  gap: 10px;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 130px) !important;
  position: relative;
}

.demande-list-vrs {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 175px) !important;
  cursor: pointer;
  grid-row: 1/2;
  background-color: #ececec;
  border-radius: 8px 8px 0 0;
  margin-top: 2px;
}

.demande-container-prv {
  display: grid;
  grid-template-columns: 270px auto;
  gap: 10px;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 150px) !important;
  position: relative;
}

.demande-container-fac {
  display: grid;
  grid-template-columns: 270px auto;
  gap: 10px;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 110px) !important;
  position: relative;
}

.card-cnt-nodata-trs {
  margin-top: 8px;
  height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto !important;
  flex-grow: 1;
}

.card-cnt-nodata-fac {
  margin-top: 8px;
  /* height: calc(100vh - 160px); */
  height: calc(100vh - 170px);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto !important;
  flex-grow: 1;
}

.card-cnt-nodata-fac2 {
  margin-top: 8px;
  height: calc(100vh - 130px);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto !important;
  flex-grow: 1;
}

.actions-trs {
  display: flex;
  flex-flow: row-reverse wrap;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: var(--formgap);
  margin-top: 8px;
}

.actions-bor {
  display: flex;
  width: 100%;
  align-items: center;
  gap: var(--formgap);
  margin-top: 8px;
  margin-bottom: 8px;
}

.action-act-trs {
  display: flex;
  gap: 5px;
}

.num-date-trs {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: 8px;
  margin-right: auto;
  padding-left: 4px;
}

.avatar-userIn-trs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bon-container-info-trs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 6.5px;
}

.typography-container-total-trs {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  color: #8a8a8a;
  padding: 4px;
}

.typography-container-total-trs1 {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  padding: 4px;
}

.typography-container-total-trs-wrh {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  color: #fe0000;
  padding: 4px;
}

.typography-container-total-trs-wrh-vch {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  color: #109b58;
  padding: 4px;
}

.typography-container-total-trs-wrh-sim {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  color: #f67c01;
  padding: 4px;
}

.typography-container-total-trs-wrh-crd {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  color: #4d80bf;
  padding: 4px;
}

.circularProgress-trs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.demande-details-trs-media {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
  grid-row: 1/3;
  overflow-y: hidden;
  overflow-x: auto;
}

.num-frns-trs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding-left: 3px;
  padding-right: 3px;
}

.brut-container-trs {
  border-top: 2px solid #eee;
  border-right: 2px solid #eee;
  border-left: 2px solid #eee;
  border-bottom: 2px solid #eee;
  border-radius: 8px 8px 0px 0px;
  padding: 4px;
  background-color: white;
  width: 100%;
}

.data-value-trs {
  flex: 1;
  text-align: right;
  color: #353535;
  font-weight: 500;
  font-size: 12px;
}

.data-value-trs-sim {
  flex: 1;
  text-align: right;
  color: red;
  font-weight: 500;
  font-size: 12px;
}

.data-title-trs {
  font-weight: 500;
  /* flex: 1; */
  font-size: 13px;
  color: #353535;
}

.total-info-trs {
  border-top: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
  border-left: 2px solid #eee;
  padding: 4px;
  background-color: white;
}

.flexwrap-trs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.data-title-net-trs {
  font-weight: 500;
  flex: 1 1;
  font-size: 13px;
  color: white;
}

.data-value-net-trs {
  text-align: right;
  color: white;
  font-weight: 500;
  font-size: 12px;
}

.pagination-container-trs {
  position: fixed;
  bottom: 27px;
  background-color: white;
  width: 270px;
  grid-row: 2/3;
  border-radius: var(---globalborder-radius);
  /* box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1); */
}

.pagination-container-trs .MuiTablePagination-root {
  font-size: 10px;
  padding-left: 0;
}

.pagination-container-trs .MuiToolbar-root-MuiTablePagination-toolbar {
  padding-left: 0;
  padding-right: 0;
}

.pagination-container-trs .MuiTablePagination-selectLabel {
  font-size: 10px;
  font-weight: bold;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0;
}

.pagination-container-trs .MuiInputBase-root {
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: -12px !important;
}

.pagination-container-trs .MuiTablePagination-toolbar {
  padding-left: 2px;
  padding-right: 2px;
}

.pagination-container-trs .MuiTablePagination-displayedRows {
  font-size: 10px;
}

.pagination-container-trs .MuiSelect-select-MuiInputBase-input.MuiSelect-select {
  padding-left: 0 !important;
  margin-left: 2px !important;
}

.pagination-container-trs .MuiTablePagination-actions {
  margin-left: 0 !important;
}

.card-cnt-trs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto !important;
  flex-grow: 1;
}

.white-space {
  /* width: 100%; */
  max-width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mui-fieldset {
  background-color: #fff;
  border-radius: var(---globalborder-radius);
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  width: 100%;
  max-width: 400px;
}

.mui-fieldset legend {
  padding: 0 10px;
  font-size: 13px;
  color: #333;
  font-weight: 500;
}

.mui-fieldset-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mui-fieldset-content label {
  font-size: 0.9rem;
  color: #555;
}

.mui-fieldset-content input {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.mui-fieldset-content input:focus {
  border-color: #3f51b5;
  outline: none;
}

.col-n {
  width: 25px;
}

.col-designation {
  width: 150px;
}

.col-value {
  width: 60px;
}

.col-com {
  width: 60px;
}

.col-prix {
  width: 100px;
}

.col-quantity {
  width: 100px;
}

.col-total {
  width: 120px;
}

.stk-filter {
  margin-bottom: 20px;
  margin-top: 10px;
}

.table-container-prv-val {
  /* height: calc(100% - 1px); */
  height: calc(100vh - 275px);
  /* height: calc(100vh - 348px); */
  /* height: calc(100vh - 261px); */
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.rdt-table {
  background-color: gray !important;
}

.solde-value {
  font-size: 14px !important;
  margin-left: auto !important;
  font-weight: 500 !important;
}

.container-imp-bleu {
  display: inline-block;
  border-top: 2px solid #007bff;
  border-right: 2px solid #007bff;
  border-left: 2px solid #007bff;
  border-radius: 0 0 8px 8px;
  background-color: #007bff;
  margin-left: 10px;
}

.container-imp-green {
  display: inline-block;
  border-top: 2px solid #0eb440;
  border-right: 2px solid #0eb440;
  border-left: 2px solid #0eb440;
  border-radius: 0 0 8px 8px;
  background-color: #0eb440;
  margin-left: 10px;
}

.container-imp-gray {
  display: inline-block;
  border-top: 2px solid #a9a9a9;
  border-right: 2px solid #a9a9a9;
  border-left: 2px solid #a9a9a9;
  border-radius: 0 0 8px 8px;
  background-color: #a9a9a9;
  margin-left: 10px;
}

.container-file-bleu {
  display: inline-block;
  border-top: 2px solid #003671;
  border-right: 2px solid #003671;
  border-left: 2px solid #003671;
  border-radius: 0 0 8px 8px;
  background-color: #003671;
}

.container-file-green {
  display: inline-block;
  border-top: 2px solid #0a7751;
  border-right: 2px solid #0a7751;
  border-left: 2px solid #0a7751;
  border-radius: 0 0 8px 8px;
  background-color: #0a7751;
}

.container-file-gray {
  display: inline-block;
  border-top: 2px solid #808080;
  border-right: 2px solid #808080;
  border-left: 2px solid #808080;
  border-radius: 0 0 8px 8px;
  background-color: #808080;
}

.actions-prv {
  display: flex;
  gap: 5px;
  margin-left: 10px;
}

.action-act-prv {
  display: flex;
  gap: 5px;
}





.grid-container-prv {
  display: grid;
  grid-template-columns: auto 40px;
  width: 100%;
  gap: 10px;
  padding: 4px;
}

.num-date-prv {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: 8px;
  margin-right: auto;
}

.table-container-prv {
  /* height: calc(100vh - 430px); */
  /* height: calc(100vh - 360px); */
  height: calc(100vh - 400px);
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.value-font-size {
  font-size: 14px !important;
  font-weight: 400;
  padding: 2px;
}

.value-font-size-trs {
  font-size: 13px !important;
  font-weight: 400;
  padding: 2px;
}

.typography-prv-com-prv {
  margin: 0;
  color: white;
  font-weight: 500;
  padding: 4px;
  font-size: 0.875rem;
}

.typography-prv-com-dml {
  margin: 0;
  color: white;
  font-weight: 500;
  padding: 2px;
  font-size: 11px !important;
  text-align: center;
}

.prv-com-info {
  display: grid;
  /* gap: 15px; */
  gap: 5px;
  border-top: 1px solid #eee;
  border-right: 2px solid #eee;
  border-left: 2px solid #eee;
  border-bottom: 2px solid #eee;
  border-radius: 0 8px 8px 8px;
  padding: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  background-color: white;
}

.flex-info-prv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.arpce-container-prv {
  border-top: 2px solid #ffe17e;
  border-right: 2px solid #ffe17e;
  border-left: 2px solid #ffe17e;
  border-bottom: 2px solid #ffe17e;
  border-radius: 8px 8px 0px 0px;
  padding: 4px;
  background-color: #ffe17e;
  width: 100%;
}

.demande-details-prv {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  /* height: 100%; */
  grid-row: 1/3;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #ececec;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 2px;
}

.card-cnt-nodata-prv {
  margin-top: 8px;
  height: calc(100vh - 230px);
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  overflow: auto !important;
  flex-grow: 1;
  margin-top: 10px;
}

.card-cnt1 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.table-prv {
  width: 100%;
}

.InfoDemande {
  display: grid;
}

.bold-label {
  font-weight: 600 !important;
  font-size: 22px !important;
}

.bold-label-rc {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.typography-container-total-prv {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
}

.Typography {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 240px);
  color: #8a8a8a;
}

.Typography .MuiTypography-root {
  font-weight: 500 !important;
  text-align: center;
}

.typography-container {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.container-info-icones-dml {
  display: inline-block;
  border-radius: 0 0 0 8px;
  background-color: #007bff;
}

.container-info-icones-dml1 {
  display: inline-block;
  border-radius: 0 0 0 0;
  background-color: #0353bc;
}

.container-info-icones-dml2 {
  display: inline-block;
  border-radius: 0 0 8px 0;
  background-color: #003671;
}

.container-info-icones-dml3 {
  display: inline-block;
  border-radius: 0 0 0 8px;
  background-color: #00b527;
}

.container-info-icones-dml4 {
  display: inline-block;
  border-radius: 0 0 0 0;
  background-color: #008719;
}

.container-info-icones-dml5 {
  display: inline-block;
  border-radius: 0 0 8px 0;
  background-color: #065f28;
}

.container-info-icones-dml6 {
  display: inline-block;
  border-radius: 0 0 8px 8px;
  background-color: #717171;
  border-left: 2px solid #4f4e4e;
  border-right: 2px solid #4f4e4e;
  border-bottom: 2px solid #4f4e4e;
}

.container-info-icones-dml7 {
  display: inline-block;
  border-radius: 0 0 8px 8px;
  background-color: #717171;
  border-left: 1px solid #4f4e4e;
  border-right: 1px solid #4f4e4e;
  border-bottom: 1px solid #4f4e4e;
}

.MuiCard3-root {
  border-radius: 8px 8px 0 0 !important;
}

.MuiCard0-root {
  border-radius: 8px 8px 8px 0 !important;
}

.MuiCard1-root {
  border-radius: 8px 8px 8px 8px !important;
}

.centred-icones {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.demande-item {
  margin: 8px;
  border-radius: 11px;
  margin-bottom: 18px;
}

.demande-item .MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
  padding: 0px !important;
}

.grid-status0 {
  width: 240px !important;
  /* width: 270px !important; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-cell-noPadding-white {
  padding: 0 !important;
  text-align: center !important;
  color: white !important;
  height: 31.2px !important;
  min-width: 100px !important;
}

.total-qte-green {
  background-color: #878787;
  color: white;
  width: 30%;
  height: fit-content;
  padding: 5px 10px;
  justify-self: end;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 10px 10px;
}

.full-grid-noGap {
  width: 100%;
  height: 100%;
  display: grid;
  gap: 0px;
}

.cmd-rel-tableContainer {
  max-height: 600px;
  border-radius: 10px 10px 0px 10px !important;
}

.cmd-rel-tableHead {
  background-color: #5fa8d3;
  color: white;
}

.slecatbleROwsCheckBox .MuiSvgIcon-root {
  font-size: 20;
  fill: var(--primary-dark) !important;
  padding: 0px !important;
}

.div-circle-ambre {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: #fff;
}

.div-flex-gap10 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cmd-rel-info-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: minmax(49%, auto) minmax(49%, auto) !important;
  min-width: 500px;
  padding: 5px;
  font-size: 13px !important;
}

.flex-column-left {
  display: flex;
  align-items: left !important;
  flex-direction: column;
}

.flex-column-gap5 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
}

.cmd-rel-info-title {
  color: #5fa8d3;
  font-weight: 700;
  font-size: 14px !important;
  margin-bottom: 10px;
}

.title-strong-colorDark {
  color: #012245;
  font-weight: 700;
}

.flex-column-order {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bold-title {
  font-weight: 600;
}

.bold-title1 {
  font-weight: bold;
  font-family: "Verdana", sans-serif !important;
  font-size: 12pt;
}







.marginleft {
  margin-left: auto !important;
}

.order-container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

}

.stat-3 {
  fill: #A80803;
  color: #A80803;
  ;
}

.stat-4 {
  fill: #027227;
  color: #027227;

}

.stat-1 {
  fill: #ff8400;
  color: #ff8400;

}

.stat-default {
  fill: #4F4E4E;
  color: #4F4E4E;

}

.statut-orange {
  background-color: #f1e12069;
  color: #ff8400;
}

.statut-rouge {
  background-color: rgb(255, 0, 0, 0.16);
  color: rgb(178, 34, 34);
}

.statut-jaune {
  background-color: #fff59d;
  color: #ffc107;
}

.statut-vert {
  background-color: rgb(0, 167, 111, 0.16);
  color: rgb(0, 120, 103);
}

.statut-gris {
  background-color: #f0f0f0;
  /* Gris clair */
  color: #333333;
  /* Gris foncé pour le texte */
}

.add-interval {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: 89%;
  height: 17px;
  margin-bottom: 3px;
}

.vch-fac-add {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  gap: 12px;
  border-radius: 5px;
  padding: 5px;
}

.tabSolde {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1060;
  overflow: auto;
}

/* #tableSoldes {
  width: 98%;
  border-collapse: collapse;
  margin: auto;
  margin-top: 0;
  border: 1px solid grey;
} */
#tableSoldes {
  width: 98%;
  border-collapse: separate;
  border-spacing: 0;
  margin: auto;
  margin-top: 0;
  border: 1px solid grey;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* 
.changeColor {
  background: #ccc !important;
} */

.clientName {
  text-align: center;
  min-width: 250px;
  /* border: 1px solid #ccc; */
  /* background-color:; */
}

/* 
.soldeLine {
  padding: 8px;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  gap: 8px;
} */
.secteur-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.soldeLine {
  padding: 8px;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
  gap: 10px;
  justify-content: center;
}

.soldeLine1 {
  padding: 8px;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  gap: 15px;
  justify-content: center;
}

.soldeLine>*:only-child {
  max-width: 600px;
  margin: 10 auto;
  /* margin-left: 30%; */
}

.soldeLine .MuiCardContent-root {
  padding: 8px !important;
}

.search-filter {
  display: flex;
  gap: 10px;
}

.bon-container-info-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 6.5px;
}



.flex-date-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.stk-trs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  gap: 12px;
  border-radius: 5px;
  padding: 5px;
}

.overflow-auto {
  overflow: auto;
}

.full-size {
  width: 100%;
  height: 100%;
}

.div-circle-type-produit {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.div-grid-gap5 {
  width: 100%;
  display: grid;
  grid-template-columns: 32px calc(100px - 32px);
  gap: 5px;
  align-items: center;
  /* justify-items: center; */
}

.flex-column-gap8 {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
}

.editibale-card {
  min-width: 50px !important;
  max-width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center !important;
}

.editibale-card:hover {
  background-color: #e8f5e9 !important;
  cursor: text;
}

.clear-icon {
  color: grey;
  padding-left: 5px;
  background-color: white;
  margin-right: 2px !important;
  padding: 0px !important;
}

.icon-size-18 {
  width: 18px;
  height: 18px;
}

.icon-size-20 {
  width: 20px;
  height: 20px;
}

.exportcsv .MuiFormControlLabel-root {
  margin-left: -1px;
  margin-right: 0px;
}

.exportcsv {
  display: flex;
  justify-content: center;
  align-items: center;
}


.pageTitleElem {
  font-weight: 600;
  /* font-size: 15px; */
  font-size: 13px;
  background: #E6E6E6;
  padding: 3px 10px;
  border-radius: 20px;
  white-space: nowrap;
  color: #5D7283;
}

.pageTitleElemMain {
  width: fit-content;
  border: 1px #E0E0E0 solid;
  background: transparent;
  /* padding: 5px; */
  padding: 3px;
  padding-bottom: 5px;
  border-radius: 20px;
  overflow: visible;
}

.horizontal-line {
  flex-grow: 1;
  height: 1px;
  background: #768A95;
  margin: 0 4px;
  width: 30px;
}

.line-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform-origin: left;
}

@media (max-width: 820px) {

  #header {
    color: #353535;
    padding: 0;
    /*position: fixed;*/
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    box-shadow: none;
    align-items: start;
    width: 100%;
  }

  #header #disconnect {
    margin-left: auto;
  }

  .pageTitleElem {
    font-weight: 600;
    /* font-size: 15px; */
    font-size: 9px;
    background: #E6E6E6;
    padding: 3px 8px;
    border-radius: 20px;
    white-space: nowrap;
    color: #5D7283;
  }

  .pageTitleElemMain {
    width: fit-content;
    border: 1px #E0E0E0 solid;
    background: transparent;
    /* padding: 5px; */
    padding: 5px;
    padding-top: 1px;
    border-radius: 20px;
    overflow: visible;
  }

  .horizontal-line {
    height: 1px;
    background: #768A95;
    margin: 0 4px;
    width: 15px;
  }

  .line-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform-origin: left;
    width: fit-content;
    margin-right: auto;
  }

}

@media (min-width: 1290px) and (max-width: 1570px) {
  .table-container-prv {
    height: calc(100vh - 412px);
  }

  .demande-details-prv {
    height: calc(100vh - 190px);
  }

  .card-cnt-nodata-prv {
    height: calc(100vh - 210px);
  }
}

@media (min-width: 959px) and (max-width: 1285px) {
  .table-container-prv {
    height: calc(100vh - 430px);
  }


  .demande-details-prv {
    height: calc(100vh - 190px);
  }

  .card-cnt-nodata-prv {
    height: calc(100vh - 210px);
  }
}


@media (max-width: 959px) {
  .pagination-container-pay {
    position: fixed;
    bottom: 33px !important;
    /* z-index: 1000; */
    width: 100% !important;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

  }

  .demande-list-trs {
    width: 100%;
    height: calc(100vh - 210px) !important;
  }

  .demande-list-vrs {
    width: 100%;
    height: calc(100vh - 200px) !important;
  }

  .demande-container-fac {
    width: 100%;
    display: flex;
  }

  .demande-list-vch {
    height: calc(100vh - 250px) !important;
  }

  .demande-list-prv {
    height: calc(100vh - 320px) !important;
  }




  .pagination-container-vch-bor {
    position: fixed;
    bottom: 30px;
    z-index: 1000;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }

  .pagination-container-trs {
    /* bottom: 0px;
    z-index: 1000; */
    width: 100%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }

  .demande-container-prv {
    width: 100%;
    display: flex;
  }

  .demande-container-trs {
    width: 100%;
    display: flex;
  }

}

@media (min-width: 767px) and (max-width: 958px) {
  .demande-list-prv {
    height: calc(100vh - 290px) !important;
  }
}

@media (min-width: 539px) and (max-width: 818px) {
  .demande-list-vrs {
    height: calc(100vh - 220px) !important;
  }
}

@media (min-width: 538px) and (max-width: 956px) {
  .demande-list-trs {
    height: calc(100vh - 190px) !important;
  }
}

@media (min-width: 954px) and (max-width: 1368px) {
  .demande-list-prv {
    height: calc(100vh - 270px) !important;
  }

  .demande-list-vch {
    height: calc(100vh - 270px) !important;
  }
}

@media (max-width: 545px) {
  .demande-list-vrs {
    height: calc(100vh - 270px) !important;
  }

}


@media (min-width: 530px) and (max-width: 720px) {
  .demande-list-trs {
    height: calc(100vh - 220px) !important;
  }
}

@media (max-width: 530px) {
  .demande-list-trs {
    height: calc(100vh - 260px) !important;
  }
}